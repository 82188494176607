<template>
  <!-- begin:: Header -->
  <div
    id="kt_header"
    ref="kt_header"
    class="header header-fixed"
  >
    <div
      class="container-fluid d-flex align-items-center justify-content-between"
    >
      <!-- begin:: Header Menu -->
      <div ref="kt_header_menu_wrapper" class="header-menu-wrapper header-menu-wrapper-left">
        <div ref="kt_header_menu" class="header-menu header-menu-mobile header-menu-layout-default">
          <!-- example static menu here -->
          <KTMenu></KTMenu>
        </div>
      </div>
      <!-- end:: Header Menu -->
      <div class="topbar">
        <KTLanguageBar></KTLanguageBar>
        <KTUserbar class="ml-5"></KTUserbar>
      </div>
    </div>
  </div>
  <!-- end:: Header -->
</template>

<script>
import KTUserbar from "@/view/layout/header/Userbar.vue";
import KTLanguageBar from "@/view/layout/header/LanguageBar.vue";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
import KTMenu from "@/view/layout/header/Menu.vue";

export default {
  name: "KTHeader",
  components: {
    KTUserbar,
    KTLanguageBar,
    KTMenu
  },
  mounted() {
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init("kt_header", "kt_header_mobile");

    // Init Header Menu
    KTLayoutHeaderMenu.init(
      this.$refs["kt_header_menu"],
      this.$refs["kt_header_menu_wrapper"]
    );
  }
};
</script>
