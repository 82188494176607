<template>
  <!--begin: User bar -->
  <div class="topbar-item">
    <b-dropdown
      ref="dropdown"
      size="sm"
      variant="link"
      toggle-class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 text-decoration-none"
      no-caret
      right
      no-flip
    >
      <template v-slot:button-content>
        <img class="h-25px w-25px rounded-sm" src="/svg/avatar.svg" />
      </template>
      <b-dropdown-text tag="div">
        <ul class="navi navi-hover">
          <li class="navi-item">
            <span class="pointer navi-link" @click="doLogout">
              <span class="svg-icon svg-icon-xl svg-icon-primary">
                <inline-svg src="/svg/shutdown.svg" />
              </span>
              <span class="navi-text ml-2">{{ $t('logout') }}</span>
            </span>
          </li>
        </ul>
      </b-dropdown-text>
    </b-dropdown>
  </div>
  <!--end: User bar -->
</template>

<script>
import { firebaseAuth } from '../../../main';
import { signOut } from 'firebase/auth';

export default {
  name: 'UserBar',
  methods: {
    doLogout() {
      if (confirm(this.$t('confirm_logout'))) {
        signOut(firebaseAuth);
      }
    }
  }
};
</script>
