<template>
  <!-- begin:: Aside -->
  <div class="brand flex-column-auto" id="kt_brand" ref="kt_brand">
    <div class="brand-logo">
      <router-link to="/">
        <img :src="assets.logoLight" :height="30" />
      </router-link>
    </div>
    <div class="brand-tools">
      <button
        class="brand-toggle btn btn-sm px-0"
        id="kt_aside_toggle"
        ref="kt_aside_toggle"
      >
        <span class="svg-icon svg-icon svg-icon-xl">
          <inline-svg class="svg-icon" src="/svg/dougle_left.svg" />
        </span>
      </button>
    </div>
  </div>
  <!-- end:: Aside -->
</template>

<script>
import KTLayoutBrand from "@/assets/js/layout/base/brand.js";
import KTLayoutAsideToggle from "@/assets/js/layout/base/aside-toggle.js";

import logoLight from '@/assets/image/logo_light.png';

export default {
  name: "KTBrand",
  data() {
    return {
      assets: {
        logoLight
      }
    }
  },
  mounted() {
    // Init Brand Panel For Logo
    KTLayoutBrand.init(this.$refs["kt_brand"]);

    // Init Aside Menu Toggle
    KTLayoutAsideToggle.init(this.$refs["kt_aside_toggle"]);
  }
};
</script>
